import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons/faFileAlt';
import { faLaptop } from '@fortawesome/free-solid-svg-icons/faLaptop';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons/faMicrophone';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons/faNewspaper';
import { faPodcast } from '@fortawesome/free-solid-svg-icons/faPodcast';
import { faRssSquare } from '@fortawesome/free-solid-svg-icons/faRssSquare';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle';
import { faTrophy } from '@fortawesome/free-solid-svg-icons/faTrophy';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons/faChevronCircleUp';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons/faChevronCircleDown';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { library, dom } from '@fortawesome/fontawesome-svg-core';

library.add(
  faBook,
  faCalendarAlt,
  faCaretDown,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronUp,
  faDownload,
  faFileAlt,
  faLaptop,
  faMicrophone,
  faNewspaper,
  faPodcast,
  faRssSquare,
  faSearch,
  faShare,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faTimesCircle,
  faTrophy,
  faUsers,
  faArrowRight,
  faArrowLeft
);

dom.watch();
